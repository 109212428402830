<template>
	<div>
		<div class="py-12 bg-white">
			<div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div class="lg:text-center">
					<h2 class="text-base font-semibold tracking-wide text-blue-600 uppercase">
						{{ $i18n("Changelog") }}
					</h2>
					<p class="mt-2 tracking-tight text-gray-900 leading-8 sm:text-lg">
						Version {{ version }} - initial release
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { version } from "@/../package.json";
export default {
	name: "Changelog",
	props: {
		version: {
			type: String,
			default: (() => {
				const versionPath = version.split(".");

				versionPath.pop();

				return versionPath.join(".");
			})(),
		},
	},
};
</script>
